import { LineChart, Line, XAxis, YAxis, ResponsiveContainer } from 'recharts';

function CustomDot(props) {
    const { cx, cy, stroke, value } = props;
    return (
        <svg x={cx - 15} y={cy -15} width={70} height={70} fill={stroke} viewBox="0 0 1024 1024">
            <rect x="0" y="0" width="500" height="500" />
            <text x="250" y="250" fill="white" fontSize="200" textAnchor="middle" dominantBaseline="middle">
                {value}
            </text>
        </svg>
    );
}
const MyLineChart = ({data = data, heading = "", details , color = "#8884d8" ,fullChart = false}) => {
    
    return (
        <div style = {{ height: fullChart ? '55vh': '18.5vh'  , display: 'flex', flexDirection: 'column' , padding: '5px', cursor: 'pointer'}}>
            <div style={{ display: 'flex', backgroundColor: color,  height: '30px', padding: '20px' , textAlign: 'left', alignItems: 'center', justifyContent: 'center',}}>
                <p>{heading}</p>
            </div>
            <div style={{ backgroundColor: "#000099", display: 'flex', justifyContent: 'space-between', width: '100%' , height: 'inherit'}}>
                
            <div style={{ 
                backgroundColor: color, 
                width: "50%", 
                overflowY: 'auto', 
                textAlign: 'left',  
                padding: '0 10px 5px 10px',
                height: fullChart ? '55vh': '13.5vh'  
            }}> 
                {details.map((detail, index) => (
                    <p key={index}> - {detail}</p>
                ))}
            </div>
            
                <ResponsiveContainer width = "50%" backgroundColor="Blue" >
                    <LineChart data={data} >
                        <XAxis dataKey="name" padding={{ right: 15, left: 15 }}  axisLine={{ stroke: 'white' }} tick ={{ stroke: 'white' , fontSize: '12px', fill: 'white' }}  stroke='white'/>
                        <YAxis axisLine={{ stroke: 'white' }} padding={{ top: 15, bottom: 15 }} tick ={{ stroke: 'white' , fontSize: '12px',  fill: 'white' }} stroke='white' />
                        <Line type="monotone" dataKey="value" stroke={color} dot={<CustomDot />} strokeWidth={3} />
                    </LineChart>
                </ResponsiveContainer>
                
            </div>
        </div>
    );
};

export default MyLineChart;
