import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Legend, ResponsiveContainer, LabelList } from 'recharts';
import SmallShield from '../../Usables/SmallSheild';

function BarChart_Awareness ({ data , sampleSizes, shield_data }) {
  
  data = data[0]

  const waveExists = (data, waveToCheck) => {
    return  data.some(item =>
      item  && item.hasOwnProperty(waveToCheck));
  };
  
  
  const legendFormatter = (value) => {
    
    const sampleSize = sampleSizes[0]; // Assuming only one entry in sample_size_data
  
    if (value.includes('Wave1')) {
        return `${value} (n = ${sampleSize.Wave1})`;
      } else if (value.includes('Wave2')) {
        return `${value} (n = ${sampleSize.Wave2})`;
      } else {
        return value;
      }
    };

    const legendFormatter_2 = (value) => {
    const sampleSize = sampleSizes[1]; // Assuming only one entry in sample_size_data
  
    if (value.includes('Wave1')) {
        return `${value} (n = ${sampleSize.Wave1})`;
      } else if (value.includes('Wave2')) {
        return `${value} (n = ${sampleSize.Wave2})`;
      } else {
        return value;
      }
    };


  const AwarenessExit = waveExists(data.Awareness || [], 'Wave1') || waveExists(data.Awareness || [], 'Wave2');
  const ContactExit = waveExists(data.Contact || [], 'Wave1') || waveExists(data.Contact || [], 'Wave2');

  return (
  <div style={{ display: 'grid', gridTemplateColumns: '90% 10%', height: '60vh'}}>
    <div style={{ display: 'flex', flexDirection: 'row', width: '100%',  height: '50vh'}}>
      
      {AwarenessExit && 
      <div style={{ flex: 1, padding: '0 10px' }}> {/* Add padding to separate charts */}
        <h3 style={{ textAlign: 'center' }}>Awareness of RM</h3>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart layout="vertical" data={data.Awareness} margin={{ top: 20, right: 50, left: 50, bottom: 5 }}>
            <XAxis type="number" tick={{ fill: 'white' }} axisLine={{ stroke: '#ffffff' }} />
            <YAxis type="category" dataKey="name" tick={{ fill: 'white' }} axisLine={{ stroke: '#ffffff' }} />
            <Legend 
              formatter={legendFormatter}
              wrapperStyle={{ color: '#ffffff' }} // Set legend text color to white
            />
            {waveExists(data.Awareness || [], 'Wave1') && (
            <Bar dataKey="Wave1" fill="#8884d8">
              <LabelList 
                dataKey="Wave1" 
                position="right" 
                style={{ fill: '#ffffff' }} 
                formatter={(value) => `${value}%`} // Format labels as percentages
              />
            </Bar>
            )}

            {waveExists(data.Awareness || [], 'Wave2') && (
            <Bar dataKey="Wave2" fill="#82ca9d">
              <LabelList 
                dataKey="Wave2" 
                position="right" 
                style={{ fill: '#ffffff' }} 
                formatter={(value) => `${value}%`} // Format labels as percentages
              />
            </Bar>
            )}

          </BarChart>
        </ResponsiveContainer>
      </div>}
            
      {ContactExit && 
      <div style={{ flex: 1, padding: ' 0 10px' }}> {/* Add padding to separate charts */}
      <h3 style={{ textAlign: 'center' }}>Awareness of Contact</h3>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart layout="vertical" data={data.Contact} margin={{ top: 20, right: 50, left: 50, bottom: 5 }}>
            <XAxis type="number" tick={{ fill: 'white' }} axisLine={{ stroke: '#ffffff' }} />
            <YAxis type="category" dataKey="name" tick={{ fill: 'white' }} axisLine={{ stroke: '#ffffff' }} />
            <Legend 
              formatter={legendFormatter_2}
              wrapperStyle={{ color: '#ffffff' }} // Set legend text color to white
            />
            {waveExists(data.Contact || [], 'Wave1') && (
            <Bar dataKey="Wave1" fill="#8884d8">
              <LabelList 
                dataKey="Wave1" 
                position="right" 
                style={{ fill: '#ffffff' }} 
                formatter={(value) => `${value}%`} // Format labels as percentages
              />
            </Bar>
            )}
            {waveExists(data.Contact || [], 'Wave2') && (
            <Bar dataKey="Wave2" fill="#82ca9d">
              <LabelList 
                dataKey="Wave2" 
                position="right" 
                style={{ fill: '#ffffff' }} 
                formatter={(value) => `${value}%`} // Format labels as percentages
              />
            </Bar>
            )}

          </BarChart>
        </ResponsiveContainer>
      </div> }

    </div>
    <div style={{ 
            position: 'relative', 
            display: 'flex', 
            justifyContent: 'flex-end',
            alignItems: 'flex-end', 
                          
            }}>
        <SmallShield 
          scale = '80%'
          waveTxt = {shield_data.waveTxt}
          fill = {shield_data.fill}
        
        />
        </div>
    </div>)
};

export default BarChart_Awareness;
