import React from 'react';
import { useLocation } from 'react-router-dom';
import ChartsComponent from './ChartsComponent';
import constants from '../Usables/Constants';
import useDataFetcher from './useDataFetcher';

const Others = () => {
    let location = useLocation();
    const sectionColor = "linear-gradient(45deg, rgba(0,153,153, 80%), rgba(0,153,153, 0.8) 100%)"; 

    const section_obj = {
        name: 'Other Banks',
        url_part: 'other_banks_results'
    }
    
    const { 
        data, loading, filters_data, selectedYear, setSelectedYear, selectedMarket, setSelectedMarket, 
        selectedSegment, setSelectedSegment, handleFilterChange 
    } = useDataFetcher(location.state.country, section_obj);

    // console.log(data);
    
    if (loading || !data) {
        return <div style={{ height:'100%',display:'flex',alignItems:'center',justifyContent:'center' }}>Loading...</div>;
    }

    const charts = [
        {   // Line Graph
            title: 'OTHER BANKS NPS SCORE',
            chart_type: 'line',
            sectionTitleColor : sectionColor,
            ...data.nps,
            // colors:['#8884d8', '#ffc107'],
            icon: 'speed'
        },
        {   // Stacked bargraph
            title: 'OTHER BANKS NPS DISTRIBUTION',
            chart_type: 'multi_series_stacked_bar',
            sectionTitleColor : sectionColor,
            ...data.rdist,
            icon: 'vertical_distribute'
        },
        {   // Bar Graph with line
            title: 'OTHER BANKS RECOMMENDATION',
            chart_type: 'column',
            sectionTitleColor : sectionColor,
            ...data.bank_recommendation,
            colors:['#FF0000', '#FF4500','#FFA500', '#FFD700' ,'#FFFF00', '#ADFF2F', '#7CFC00','#32CD32', '#00FF00' ,'#008000'],
            icon: 'trending_up'
        },
        {
            title: 'OTHER BANKS DELIVERY OF EXPERIENCE SCORES',
            chart_type: 'delivery_exprience_bar',
            sectionTitleColor : sectionColor,
            ...data.delivery_experience,
            colors:['#FF0000', '#FF4500','#FFA500', '#FFD700' ,'#FFFF00', '#ADFF2F', '#7CFC00','#32CD32', '#00FF00' ,'#008000', '#000000'],
            icon: 'theater_comedy'
        }
    ]
    return (
        <ChartsComponent 
            location={location} 
            n_child_divs={charts.length}
            charts={charts}
            filters_data={filters_data}
            selected_year={selectedYear}
            set_selected_year={setSelectedYear}
            selected_market={selectedMarket}
            set_selected_market={setSelectedMarket}
            selected_segment={selectedSegment}
            set_selected_segment={setSelectedSegment}
            handleFilterChange={handleFilterChange}
        />
    );
};

export default Others;
