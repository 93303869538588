import React from 'react';

const CustomSVG = (props) => {
  const { 
    width = "150", 
    height = "150", 
    fill = "red", 
    style = {}, 
    scale = "1",
    waveSample = "", 
    waveTxt = "", 
    textColor = "white",
    secondDivOffset = '10px' // New prop to control the vertical offset for the second div
  } = props;

  // Determine if waveSample and waveTxt have values
  const hasWaveSample = waveSample && waveSample.trim() !== '';
  const hasWaveTxt = waveTxt && waveTxt.trim() !== '';

  // Define styles for the SVG
  const svgStyle = {
    ...style,
    transform: `scale(${scale})`,
    transformOrigin: 'center',
    overflow: 'visible',
    padding: '0px',
  };

  // Define styles for the container
  const containerStyle = {
    position: 'relative', // Ensures the text is positioned relative to this container
    width: `${width}px`,  // Matches the width of the SVG
    height: `${height}px`, // Matches the height of the SVG
    overflow: 'hidden',    // Hides any overflow outside the SVG bounds
    display: 'flex',
    justifyContent: 'center', // Center horizontally
    alignItems: 'center', // Center vertically
    textAlign: 'center' // Center-aligns the text horizontally
  };

  // Define styles for the text containers
  const textStyle = {
    position: 'absolute', // Positions the text over the SVG
    width: '80%', // Ensure the text container is appropriately sized
    height: 'auto', // Adjust height to fit content
    display: 'flex',
    flexDirection: 'column', // Stack text vertically
    justifyContent: 'center', // Center text items vertically
    alignItems: 'center', // Center text items horizontally
    whiteSpace: 'normal', // Allows text to wrap normally
    wordWrap: 'break-word', // Breaks long words if needed
    color: textColor, // Sets the text color
    fontSize: '16px', // Adjust font size to fit within the SVG
    lineHeight: '1.2', // Adjust line height for better text spacing
    boxSizing: 'border-box', // Ensures padding is included in the element's width and height
    zIndex: 1, // Ensures the text is above the SVG
    top: '30%', // Default top positioning
  };

  // Define additional style for the second div
  const secondTextStyle = {
    ...textStyle,
    top: `calc(40% + ${secondDivOffset})`, // Move the text upwards
    fontSize: '24px', // Increase font size for waveSample
    fontWeight: 'bold', // Make the font bold
    marginBottom: '0',
  };

  // Only render the container if either waveTxt or waveSample is present
  return (hasWaveTxt || hasWaveSample) ? (
    <div style={containerStyle}>
{/* Conditionally render the first text container */}
    {hasWaveTxt && (
      <div 
        style={{
          ...textStyle,
          top: hasWaveSample ? '10%' : '30%', // Move to top if waveSample is true, else default position
          width: hasWaveSample ? '80%' : '100%', // Set width to 80% if waveSample is true
        }}>
        <p>{waveTxt}</p>
      </div>
    )}

    {/* Conditionally render the second text container with vertical offset */}
    {hasWaveSample && (
      <div 
        style={{
          ...secondTextStyle,
          top: '50%', // Center the waveSample text in the middle
          transform: 'translateY(-50%)', // Adjust to ensure it's truly centered
        }}>
        <p>{waveSample}</p>
      </div>
    )}


      {/* SVG */}
      <svg
        width={width}
        height={height}
        viewBox="3560 2000 140 160"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        xmlSpace="preserve"
        style={svgStyle}
      >
        <path
          d="M3693.47 2020.02C3693.3 2016.56 3690.78 2013.59 3687.32 2012.79 3668.98 2009.09 3650.29 2007.08 3631.52 2007 3612.75 2007.16 3594.07 2009.09 3575.72 2012.78 3572.18 2013.59 3569.74 2016.56 3569.57 2020.01 3568.14 2034.8 3564.78 2072.72 3566.46 2087.99 3570.33 2122.37 3595.92 2144.55 3626.81 2155.55 3628.32 2156.12 3629.84 2156.6 3631.44 2157L3632.53 2156.68 3632.62 2156.68C3636.49 2155.39 3640.28 2154.03 3643.89 2152.42 3647.58 2150.75 3651.26 2149.08 3654.95 2147.41 3658.1 2145.53 3662.38 2142.75 3667.04 2138.92 3671.82 2135 3682.68 2126.03 3689.69 2111.65 3693.17 2104.51 3695.53 2096.6 3696.5 2087.91 3698.27 2072.72 3694.9 2034.72 3693.47 2020.02Z"
          fill={fill}
          fillRule="evenodd"
        />
      </svg>
    </div>
  ) : null; // If both are empty, render nothing
};

export default CustomSVG;
