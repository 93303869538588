import React from 'react';

const DottedConnector = ({ imgSize }) => {
    return (
        <svg width={imgSize*0.1} height={1}>
            <rect width="100%" height="1" stroke="white" strokeDasharray="4 2" strokeWidth="4"  fill="transparent" />
        </svg>
    );
};

export default DottedConnector;
