import React from 'react';
import { useLocation } from 'react-router-dom';
import TopBar from './components/TopBar';
import NavigationButtons from './components/NavigationButtons';

const AppWrapper = ({ children }) => {
    const location = useLocation();
    const isLoginPage = location.pathname === "/";
    const country = location.state? location.state.country : undefined;
    return (
        <>
            <div className="background"></div>
            
            <TopBar country={country} />
            {/* declaring linearGradient for universal use */}
            <svg height={0}>
                <defs>
                <linearGradient id="blueGradient" x1="0%" y1="0%" x2="100%" y2="100%">
                    <stop offset="0%" style={{stopColor: "rgba(0, 0, 139, 0.8)", stopOpacity: 1}} />
                    <stop offset="100%" style={{stopColor: "rgba(100, 120, 200, 0.8)", stopOpacity: 1}} />
                </linearGradient>
                </defs>
            </svg>
            <div id='body'>
                {!isLoginPage && (
                    <>
                        <div className="overlay"></div>
                        <NavigationButtons />
                    </>
                )}
                <div id='heart'>
                    {children}
                </div>
            </div>
        </>
    );
};

export default AppWrapper;
