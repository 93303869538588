import React from 'react';
import { useLocation } from 'react-router-dom';
import ChartsComponent from './ChartsComponent';


const RelationshipManager = () => {
    let location = useLocation();
    const sectionColor = "linear-gradient(45deg, rgba(127,168,255, 0.8) 0%, rgba(127,168,255, 0.8) 100%)"; 
    const charts = [
        {    // Line Graph
            title: 'RELATIONSHIP MANAGER AVERAGE NPS',
            chart_type: 'line',
            sectionTitleColor : sectionColor,
            data: [
                { name: 'Wave 1', PPB_Total: 90, Main_Market: 50, Affluent_Market: 70 },
                { name: 'Wave 2', PPB_Total: 80, Main_Market: 60, Affluent_Market: 95 },
            ],
            sample_size_data: [
                { name: 'Wave 1', PPB_Total: 90, Main_Market: 50, Affluent_Market: 70 },
                { name: 'Wave 2', PPB_Total: 80, Main_Market: 60, Affluent_Market: 95 },
            ],
            shield_data : {waveTxt:"Market" , fill : "blue" },
            colors:['#8884d8', '#ffc107'],
            icon: 'speed'
        },
        {   // Stacked bargraph
            title: 'RELATIONSHIP MANAGER NPS DISTRIBUTION',
            chart_type: 'multi_series_stacked_bar',
            sectionTitleColor : sectionColor,
            data: [
                { name: 'PPB Total', Wave1_Promoters: 80, Wave1_Passives: 10, Wave1_Detractors: 10, Wave2_Promoters: 70, Wave2_Passives: 20, Wave2_Detractors: 10 },
                { name: 'Main Market', Wave1_Promoters: 70, Wave1_Passives: 20, Wave1_Detractors: 10, Wave2_Promoters: 70, Wave2_Passives: 20, Wave2_Detractors: 10 },
                { name: 'Blue', Wave1_Promoters: 70, Wave1_Passives: 20, Wave1_Detractors: 10, Wave2_Promoters: 70, Wave2_Passives: 20, Wave2_Detractors: 10 },
                { name: 'Silver', Wave1_Promoters: 70, Wave1_Passives: 20, Wave1_Detractors: 10, Wave2_Promoters: 70, Wave2_Passives: 20, Wave2_Detractors: 10 },
                { name: 'Affluent Market', Wave1_Promoters: 70, Wave1_Passives: 20, Wave1_Detractors: 10, Wave2_Promoters: 70, Wave2_Passives: 20, Wave2_Detractors: 10 },
                { name: 'Executive', Wave1_Promoters: 70, Wave1_Passives: 20, Wave1_Detractors: 10, Wave2_Promoters: 70, Wave2_Passives: 20, Wave2_Detractors: 10 },
                { name: 'Private', Wave1_Promoters: 70, Wave1_Passives: 20, Wave1_Detractors: 10, Wave2_Promoters: 70, Wave2_Passives: 20, Wave2_Detractors: 10 },
            ],
            sample_size_data: [
                { name: 'PPB Total', Wave1: 8, Wave2: 10 },
                { name: 'Main Market', Wave1: 80, Wave2: 10 },
                { name: 'Blue', Wave1: 80, Wave2: 1},
                { name: 'Silver', Wave1: 80, Wave2: 10 },
                { name: 'Affluent Market', Wave1: 80, Wave2: 10 },
                { name: 'Executive', Wave1: 80, Wave2: 10 },
                { name: 'Private', Wave1: 80, Wave2: 10 }
              ],
            shield_data : {waveTxt:"Market" , fill : "green" },
            colors:[],
            icon: 'vertical_distribute'
        },
        {   // Bar Graph with a line
            title: 'RELATIONSHIP MANAGER DELIVERY OF EXPERIENCE',
            chart_type: 'column',
            sectionTitleColor : sectionColor,
            data: [
                { name: 'Stanbic Bank', Wave1: 72, Wave2: 70 },
                { name: 'Main Market', Wave1: 72, Wave2: 80 },
                { name: 'Blue', Wave1: 72, Wave2: 70 },
                { name: 'Silver', Wave1: 72, Wave2: 70 },
                { name: 'Affluent Market', Wave1: 72, Wave2: 70 },
                { name: 'Executive', Wave1: 72, Wave2: 70 },
                { name: 'Private', Wave1: 20, Wave2: 70 },
            ],
            sample_size_data: [
                {Wave1: 20, Wave2: 10 },
              ],
            shield_data :{waveTxt:"Market" , fill : "orange" },
            colors:['#FF0000', '#FF4500','#FFA500', '#FFD700' ,'#FFFF00', '#ADFF2F', '#7CFC00','#32CD32', '#00FF00' ,'#008000'],
            icon: 'trending_up'
        },
        {   
            // Frequency Sidebars
            title: 'RELATIONSHIP MANAGER CONFIRMATION AND INTERACTION FREQUENCY',
            chart_type: 'relationship_sidebars',
            sectionTitleColor : sectionColor,
            data:[ {
                Awareness:[
                    { name: 'No', Wave1: 28, Wave2: 28 },
                    { name: 'Yes', Wave1: 72, Wave2: 72 }],
                Contact:[
                    { name: 'Less frequently', Wave1: 20, Wave2: 20 },
                    { name: 'Happy with the current frequency', Wave1: 20, Wave2: 20 },
                    { name: 'More frequently', Wave1: 60, Wave2: 60 }]
                 }],
            sample_size_data: [
                { name: 'Awareness', Wave1: 28, Wave2: 2 },
                { name: 'Contact', Wave1: 720, Wave2: 72 }
            ],
            shield_data : {waveTxt:"Market" , fill : "blue" },
            colors:['#FF0000', '#FF4500','#FFA500', '#FFD700' ,'#FFFF00', '#ADFF2F', '#7CFC00','#32CD32', '#00FF00' ,'#008000'],
            icon: 'theater_comedy'
        }
    ]

    return (
        <ChartsComponent location={location}  n_child_divs={charts.length} charts={charts}  />
    );
};

export default RelationshipManager;
