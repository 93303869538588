import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Cell, LabelList, ResponsiveContainer } from 'recharts';
import SmallShield from '../../Usables/SmallSheild';


function Double_BarCharts_statisfation({ data , shield_data,  chart_shield}) {

  const side_bar_graph_colors = ['#FF0000', '#FF4500','#FFA500', '#FFD700' ,'#FFFF00', '#ADFF2F', '#7CFC00','#32CD32', '#00FF00' ,'#008000'].reverse();

  // console.log(data)

  data.sort((a, b) => {
    const ratingA = parseInt(a.name.match(/\d+/)[0]);
    const ratingB = parseInt(b.name.match(/\d+/)[0]);
    return ratingA - ratingB;
  }).reverse();

  const waveExists = (data, waveToCheck) => {
    return  data.some(item =>
      item  && item.hasOwnProperty(waveToCheck));
  };
  
  const waveExist1 = waveExists(data, "Wave1");
  const waveExist2 = waveExists(data, "Wave2");

  data = data.map((data, index) => {
    const { name, Wave1, Wave2 } = data;
    const color = side_bar_graph_colors[index % side_bar_graph_colors.length];
    return { name, Wave1, Wave2, color };
  })

  const dmnRng = [0, 80];
  return (
    <>
      <div style={{ width: '100%', height: '100%'}}>

        <div style={{  display: 'grid', gridTemplateColumns: '90% 10%',width: '100%', height: '95%', }} > {/* Graph container */}
          <div style={{ width: '100%', height: '100%'}}>

                {waveExist1 && (
                <div style={{ display: 'flex',  height: waveExist2 ? '45%' : '90%' }} >
                    {/* The first graph */}
                    <div style={{ 
                      position: 'relative', 
                      display: 'flex',
                      }}>
                    <SmallShield 
                      scale="100%" 
                      secondDivOffset = '-10px'
                      waveTxt = {chart_shield[0] && chart_shield[0].waveTxt || ""}
                      waveSample = {chart_shield[0] && chart_shield[0].waveSample || ""}
                      fill = {chart_shield[0] && chart_shield[0].fill || ""}
                    />
                  </div>

                    <ResponsiveContainer width="100%" style={{ paddingLeft: '20px'}}>
                    <BarChart
                      data={data}
                      layout="vertical"
                      margin={{
                        top: 20,
                        right: 0,
                        left: 0,
                        bottom: 5,
                      }}
                    >
                      {!waveExist2 ?  <XAxis type="number" tick={{ fill: '#ffffff' }} domain={dmnRng}/> : <XAxis type="number" tick={{ fill: '#ffffff' }} domain={dmnRng} hide />}

                      <YAxis type="category" tickSize={10} hide />
                      <Bar dataKey="Wave1" fill="#8884d8">
                        {data.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={entry.color} />
                        ))}
                        <LabelList dataKey="Wave1" position="right" style={{ fill: '#ffffff' }} />
                      </Bar>
                    </BarChart>
                    </ResponsiveContainer>
                </div>
                )}

                { waveExist2 && (
                <div style={{ display: 'flex',  height:  waveExist1 ? '45%' : '90%' }} >
                    {/* The second graph */}
                    <div style={{ 
                      position: 'relative', 
                      display: 'flex',       
                      }}>
                    <SmallShield 
                      scale="100%"
                      secondDivOffset = '-10px'
                      waveTxt = {chart_shield[1] && chart_shield[1].waveTxt || ""}
                      waveSample = {chart_shield[1] && chart_shield[1].waveSample || ""}
                      fill = {chart_shield[1] && chart_shield[1].fill || ""}
                    />
                  </div>
                    <ResponsiveContainer width="100%" style={{ paddingLeft: '20px'}}>
                    <BarChart
                    data={data}
                    layout="vertical"
                    margin={{
                      top: 20,
                      right: 0,
                      left: 0,
                      bottom: 5,
                    }}
                    >
                    <XAxis type="number" tick={{ fill: '#ffffff' }} domain={dmnRng} />
                    <YAxis type="category" tickSize={10} hide />
                    <Bar dataKey="Wave2" fill="#8884d8">
                      {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.color} />
                      ))}
                      <LabelList dataKey="Wave2" position="right" style={{ fill: '#ffffff' }} />
                    </Bar>
                    </BarChart>
                    </ResponsiveContainer>
                </div>)}
                 
            </div>
            <div style={{ 
              position: 'relative', 
              display: 'flex', 
              justifyContent: 'flex-end', // Aligns to the right
              alignItems: 'flex-end',     // Aligns to the bottom
                    
              }}>
            <SmallShield 
            scale = '80%'
            waveTxt = {shield_data.waveTxt}
            fill = {shield_data.fill}
            />
            
          </div>
        </div>
              
        
        {/* The legend */}
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', marginTop: '20px' }}>
            {[...data].reverse().map((entry, index) => (
              <div
                key={index}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  margin: '0 10px',
                }}
              >
                <p style={{ fontSize: '16px', margin: '0 5px' }}>{entry.name}</p>
                <div style={{ width: '15px', height: '15px', backgroundColor: entry.color }} />
              </div>
            ))}
          </div>

      </div>
      
    </>
  );
}

export default Double_BarCharts_statisfation;
