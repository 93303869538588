import React, { useState } from 'react';
import './styles/globals.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Navigate } from 'react-router';
import Login from './components/Auth/Login';
import Home from './components/Home/Home';
import ChooseCountry from './components/Home/ChooseCountry';
import Section from './components/Section/Section';
import PPBTotalResults from './components/Results/PPBTotalResults';
import Experience from './components/Results/Experience';
import Others from './components/Results/Others';
import Branch from './components/Results/Branch';
import RelationshipManager from './components/Results/RelationshipManager';
import Wealth from './components/Results/Wealth';
import AppWrapper from './AppWrapper';

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem('isLoggedIn') === 'true');

  return (
    <Router>
      <AppWrapper>
        <Routes>
          <Route path="/" element={<Login setIsLoggedIn={setIsLoggedIn} />} />
          <Route path="/home" element={isLoggedIn ? <Home /> : <Navigate to="/" />} />
          <Route path="/choose-country" element={isLoggedIn ? <ChooseCountry /> : <Navigate to="/" />} />
          <Route path="/:section" exact element={isLoggedIn ? <Section /> : <Navigate to="/" />} />
          <Route path="/ppb/total-results" element={isLoggedIn ? <PPBTotalResults /> : <Navigate to="/" />} />
          <Route path="/ppb/experience" element={isLoggedIn ? <Experience /> : <Navigate to="/" />} />
          <Route path="/ppb/others" element={isLoggedIn ? <Others /> : <Navigate to="/" />} />
          <Route path="/ppb/branch" element={isLoggedIn ? <Branch /> : <Navigate to="/" />} />
          <Route path="/ppb/relationship-manager" element={isLoggedIn ? <RelationshipManager /> : <Navigate to="/" />} />
          <Route path="/ppb/wealth" element={isLoggedIn ? <Wealth /> : <Navigate to="/" />} />
          {/* Repeat for BCB if needed */}
        </Routes>
      </AppWrapper>
    </Router>
  );
};

export default App;
