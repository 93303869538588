import React, { useEffect, useState } from 'react';

const getImageAspectRatio = (src) => {
    return new Promise((resolve, reject) => {
        const img = new Image();

        img.onload = function() {
            resolve(this.width / this.height);
        };

        img.onerror = function() {
            reject(new Error('Failed to load image'));
        };

        img.src = src;
    });
};

const RingButton = ({ imageUrl, imgSize }) => {
    const imageHeight = imgSize*0.35; // The side height of your image
    const circleCenter = imgSize/2; // The center of your circle
    const imageVerticalPosition = circleCenter - imageHeight / 2; // Position for the image to be centered
    const [aspectRatio, setAspectRatio] = useState(null);

    useEffect(() => {
        getImageAspectRatio(imageUrl)
        .then(aspectRatio => setAspectRatio(aspectRatio))
        .catch(error => console.error(error));
    }, [imageUrl]);
    return (
        <svg width={imgSize} height={imgSize}>
            
            <circle cx={circleCenter} cy={circleCenter} r={(imgSize*0.48)} stroke="white" strokeDasharray="4 2" strokeWidth="2" fill="transparent" />
            <circle cx={circleCenter} cy={circleCenter} r={imageHeight} stroke="url(#blueGradient)" strokeWidth={(imgSize*0.1)} fill="transparent" />
            {aspectRatio && <image href={imageUrl} y={imageVerticalPosition} x={(imgSize - imageHeight * aspectRatio) / 2} height={imageHeight} />}
        </svg>
        
    );
};

export default RingButton;
