import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Previous_Button from '../imgs/Previous_Button.png';
import Home_button from '../imgs/Home_button.png';
import Final_Logout_Button from '../imgs/Final_Logout_Button.png';

import '../styles/nav_btns.css';

const NavigationButtons = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    // const { path, category } = props; // assuming path and category are passed as props

    const goBack = () => {
        navigate(-1);
    }

    const goHome = () => {
        navigate('/home', { state:{ country:location.state.country } });
    }

    const logout = () => {
        navigate('/');
    }

    // console.log(location.state); // Access the state using location.state

    return (
        <div className="nav_btns">
            <div className="nav-btn-cont">
                <a className="nav-btn">
                    <img onClick={goBack} src={Previous_Button} height="100%" />
                </a>
            </div>

            <div className="nav-btn-cont">
                <a className="nav-btn">
                    <img onClick={goHome} src={Home_button} height="100%" />
                </a>
            </div>

            <div className="nav-btn-cont">
                <a className="nav-btn">
                    <img onClick={logout} src={Final_Logout_Button} height="100%" />
                </a>
            </div>
        </div>
    );
}

export default NavigationButtons;
