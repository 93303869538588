import { XAxis, YAxis, ResponsiveContainer, Legend } from 'recharts';
import { LineChart, Tooltip, Line } from 'recharts';
import SmallShield from '../../Usables/SmallSheild';

function CustomDot(props) {
    const { cx, cy, stroke, value } = props;

    return (
        <svg x={cx - 10} y={cy - 10} width={70} height={70} fill={stroke} viewBox="0 0 1024 1024">
            <rect x="0" y="0" width="500" height="500" />
            <text x="250" y="250" fill="white" fontSize="200" textAnchor="middle" dominantBaseline="middle">
                {value}
            </text>
        </svg>
    );
}

const legendFormatter = (value) => {
    return <span style={{ color: 'white', display: 'inline-block', marginTop: '10px' }}>{value}</span>;
};

function LineCharts({ data, lineColors,  shield_data  }) {

    const side_space = 250;
    const colors = lineColors;

    const keysFromObject = Object.keys(data[0]);
    keysFromObject.shift(); //removing the first key (name)

    return (
        <div style={{ position: 'relative', width: '100%', height: '100%' }}>
            <ResponsiveContainer minWidth={600} minHeight={300}>
                <LineChart data={data}>
                    <XAxis 
                        dataKey="name" 
                        tickLine={{ stroke: 'white' }} 
                        axisLine={{ stroke: '#ffffff' }} 
                        padding={{ right: side_space, left: side_space }} 
                        tick={{ fill: 'white' }} 
                        fontSize={20} 
                    />
                    <YAxis 
                        tickLine={false} 
                        axisLine={false} 
                        margin={10} 
                        tick={{ fill: '#ffffff' }} 
                        fontSize={14} 
                    />
                    <Tooltip cursor={false} />
                    <Legend 
                        verticalAlign="top"
                        layout="vertical"
                        align="right"
                        iconType="Square"
                        formatter={legendFormatter} 
                    />
                    {keysFromObject.map((item, index) => (
                        <Line 
                            key={index} 
                            dataKey={item} 
                            stroke={colors[index]} 
                            strokeWidth={5} 
                            fill={colors[index]} 
                            dot={<CustomDot />} 
                            activeDot={false} 
                        />
                    ))}
                </LineChart>
            </ResponsiveContainer>

            <div style={{ 
                position: 'absolute', 
                bottom: '0', 
                right: '0', 
                margin: '10px', // Optional: adds margin around the SmallShield
            }}>
                <SmallShield 
                    
                    scale = '80%'
                    fill = {shield_data.fill}
                    waveTxt={shield_data.waveTxt}
                    
                    
                />
            </div>
        </div>
    );
}

export default LineCharts;
