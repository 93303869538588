
function Flag({ img, country, onClick, width }) {
    const flag_styles = width ? { width:width, height:width } : {};
    return (
       <div className='flag-and-name' onClick={onClick ? () => onClick(country) : undefined} >
            <img src={img} alt={country} className="flag" style={flag_styles} />
            <div className='country-name'>{country}</div>
       </div>
    );
  }

export default Flag;