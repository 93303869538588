import React from 'react';
import { useLocation } from 'react-router-dom';
import ChartsComponent from './ChartsComponent';
import constants from '../Usables/Constants';
import useDataFetcher from './useDataFetcher';

const Experience = () => {
    let location = useLocation();
    const company = constants.reporting_countries[location.state.country].company;
    const sectionColor = "linear-gradient(45deg, rgba(72,127,217) 0%, rgba(100, 120, 200, 0.8) 100%)"; 

    const section_obj = {
        name: 'Experience',
        url_part: 'sb_experience_results'
    }
    
    const { 
        data, loading, filters_data, selectedYear, setSelectedYear, selectedMarket, setSelectedMarket, 
        selectedSegment, setSelectedSegment, handleFilterChange 
    } = useDataFetcher(location.state.country, section_obj);

    if (loading || !data) {
        return <div style={{ height:'100%',display:'flex',alignItems:'center',justifyContent:'center' }}>Loading...</div>;
    }
    // console.log(data);
    
    const charts = [
        {
            // Line Graph
            title: company.toUpperCase() + ' EXPERIENCE SCORES',
            chart_type: 'line',
            sectionTitleColor : sectionColor,
            ...data.sb_exp_score,
            icon: 'speed'
        },

        {   // Bar Graph with line
            title: company.toUpperCase() + ' DELIVERY OF EXPERIENCE',
            chart_type: 'column',
            sectionTitleColor : sectionColor,
            ...data.delivery_experience,
            // colors:['#FF0000', '#FF4500','#FFA500', '#FFD700' ,'#FFFF00', '#ADFF2F', '#7CFC00','#32CD32', '#00FF00' ,'#008000'],
            icon: 'trending_up'
        },
        {   // Line Graph with details
            sectionTitleColor : sectionColor,
            title: 'WHAT MATTERS',
            chart_type: 'matters_linegraph_with_detail',
            ...data.what_matters,
            
            colors:['#FF0000', '#FF4500','#FFA500', '#FFD700' ,'#FFFF00', '#ADFF2F', '#7CFC00','#32CD32', '#00FF00' ,'#008000'],
            icon: 'theater_comedy'

        }]


    return (
        <ChartsComponent 
            location={location} 
            n_child_divs={charts.length}
            charts={charts}
            filters_data={filters_data}
            selected_year={selectedYear}
            set_selected_year={setSelectedYear}
            selected_market={selectedMarket}
            set_selected_market={setSelectedMarket}
            selected_segment={selectedSegment}
            set_selected_segment={setSelectedSegment}
            handleFilterChange={handleFilterChange}
        />
    );
};

export default Experience;
