const url = new URL(window.location.href);
const port_suffix = url.hostname==='localhost'? ':8000' : ''

const constants = {
    reporting_countries: {
        'Angola': { domain: '@standardbank.co.ao', company: 'Standard Bank' },
        'Botswana': { domain: '@stanbic.com', company: 'Standbic Bank' },
        'Eswatini': { domain: '@stanbic.com', company: 'Standbic Bank' },
        'Ghana': { domain: '@standardbank.co.gh', company: 'Standard Bank' },
        'Kenya': { domain: '@stanbic.com', company: 'Standbic Bank' },
        'Lesotho': { domain: '@stanbic.com', company: 'Standbic Bank' },
        'Malawi': { domain: '@standardbank.co.mw', company: 'Standard Bank' },
        'Mozambique': { domain: '@standardbank.co.mz', company: 'Standard Bank' },
        'Namibia': { domain: '@standardbank.co.na', company: 'Standard Bank' },
        'Nigeria': { domain: '@stanbicibtc.com', company: 'Standbic Bank' },
        'Tanzania': { domain: '@stanbic.co.tz', company: 'Standbic Bank' },
        'Uganda': { domain: '@stanbic.com', company: 'Standbic Bank' },
        'Zambia': { domain: '@stanbic.com', company: 'Standbic Bank' },
        'Zimbabwe': { domain: '@stanbic.com', company: 'Standbic Bank' },
    },
    other_flags: {
        'Country Comparison': { img: 'https://ontheworldmap.com/africa/africa-map.jpg' },
        'Offshore': { img: 'https://cdn1.iconfinder.com/data/icons/earth-environment-set-2/128/globes-11-512.png' },
    },
    country_flags: {
        'Angola': { img: 'https://flagcdn.com/w160/ao.png' },
        'Botswana': { img: 'https://flagcdn.com/w160/bw.png' },
        'Zimbabwe': { img: 'https://flagcdn.com/w160/zw.png' },
        'Algeria': { img: 'https://flagcdn.com/w160/dz.png' },
        'Benin': { img: 'https://flagcdn.com/w160/bj.png' },
        'Burkina Faso': { img: 'https://flagcdn.com/w160/bf.png' },
        'Burundi': { img: 'https://flagcdn.com/w160/bi.png' },
        'Cameroon': { img: 'https://flagcdn.com/w160/cm.png' },
        'Cape Verde': { img: 'https://flagcdn.com/w160/cv.png' },
        'Central African Republic': { img: 'https://flagcdn.com/w160/cf.png' },
        'Chad': { img: 'https://flagcdn.com/w160/td.png' },
        'Comoros': { img: 'https://flagcdn.com/w160/km.png' },
        'Democratic Republic of the Congo': { img: 'https://flagcdn.com/w160/cd.png' },
        'Republic of the Congo': { img: 'https://flagcdn.com/w160/cg.png' },
        'Djibouti': { img: 'https://flagcdn.com/w160/dj.png' },
        'Egypt': { img: 'https://flagcdn.com/w160/eg.png' },
        'Equatorial Guinea': { img: 'https://flagcdn.com/w160/gq.png' },
        'Eritrea': { img: 'https://flagcdn.com/w160/er.png' },
        'Eswatini': { img: 'https://flagcdn.com/w160/sz.png' },
        'Ethiopia': { img: 'https://flagcdn.com/w160/et.png' },
        'Gabon': { img: 'https://flagcdn.com/w160/ga.png' },
        'The Gambia': { img: 'https://flagcdn.com/w160/gm.png' },
        'Ghana': { img: 'https://flagcdn.com/w160/gh.png' },
        'Guinea': { img: 'https://flagcdn.com/w160/gn.png' },
        'Guinea-Bissau': { img: 'https://flagcdn.com/w160/gw.png' },
        'Ivory Coast': { img: 'https://flagcdn.com/w160/ci.png' },
        'Kenya': { img: 'https://flagcdn.com/w160/ke.png' },
        'Lesotho': { img: 'https://flagcdn.com/w160/ls.png' },
        'Liberia': { img: 'https://flagcdn.com/w160/lr.png' },
        'Libya': { img: 'https://flagcdn.com/w160/ly.png' },
        'Madagascar': { img: 'https://flagcdn.com/w160/mg.png' },
        'Malawi': { img: 'https://flagcdn.com/w160/mw.png' },
        'Mali': { img: 'https://flagcdn.com/w160/ml.png' },
        'Mauritania': { img: 'https://flagcdn.com/w160/mr.png' },
        'Mauritius': { img: 'https://flagcdn.com/w160/mu.png' },
        'Morocco': { img: 'https://flagcdn.com/w160/ma.png' },
        'Mozambique': { img: 'https://flagcdn.com/w160/mz.png' },
        'Namibia': { img: 'https://flagcdn.com/w160/na.png' },
        'Niger': { img: 'https://flagcdn.com/w160/ne.png' },
        'Nigeria': { img: 'https://flagcdn.com/w160/ng.png' },
        'Rwanda': { img: 'https://flagcdn.com/w160/rw.png' },
        'Sao Tome and Principe': { img: 'https://flagcdn.com/w160/st.png' },
        'Senegal': { img: 'https://flagcdn.com/w160/sn.png' },
        'Seychelles': { img: 'https://flagcdn.com/w160/sc.png' },
        'Sierra Leone': { img: 'https://flagcdn.com/w160/sl.png' },
        'Somalia': { img: 'https://flagcdn.com/w160/so.png' },
        'South Africa': { img: 'https://flagcdn.com/w160/za.png' },
        'South Sudan': { img: 'https://flagcdn.com/w160/ss.png'},
        'Sudan': { img: 'https://flagcdn.com/w160/sd.png' },
        'Swaziland': { img: 'https://flagcdn.com/w160/sz.png' },
        'Tanzania': { img: 'https://flagcdn.com/w160/tz.png' },
        'Togo': { img: 'https://flagcdn.com/w160/tg.png' },
        'Tunisia': { img: 'https://flagcdn.com/w160/tn.png' },
        'Uganda': { img: 'https://flagcdn.com/w160/ug.png' },
        'Western Sahara': { img: 'https://flagcdn.com/w160/eh.png' },
        'Zambia': { img: 'https://flagcdn.com/w160/zm.png' }
    }, 
    pI: 3.14,
    API_URL: `${url.protocol}//${url.hostname}${port_suffix}/api/`,
}

export default constants;