import React from 'react';
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer } from 'recharts';
import SmallShield from '../../Usables/SmallSheild';

const CustomBarChart = ({ data, title, reverse , showChart, isData = false , colors}) => {

    return (
        <div style={{ width: reverse ? (showChart ? '80%' : isData ? '380px' : '15%') :  "80%", textAlign: 'center', margin: '0 5px 0 5px', transform: reverse && showChart ? 'scaleX(-1)' : 'none' }}>
            <h3 style={{ margin: '0', transform: reverse ? 'scaleX(-1)' : 'none', color: showChart ? 'white' : 'transparent' }}>
                {title}
            </h3>

            <ResponsiveContainer width="100%" height="90%">
                <BarChart
                    data={data}
                    layout="vertical"
                    barSize={7}
                    barGap={10}
                    margin={{ top: 20, bottom: 20 }}
                >
                    { !showChart ? 
                        <YAxis type="category"
                            dataKey="name"
                            stroke='none'
                            width={300}
                            tick={{
                                fill: 'white', 
                                interval: 0, 
                                textAnchor: 'middle',
                                fontSize: 10, 
                                transform: 'translate(-190, 0)'
                            }}
                        />
                        : <YAxis type="category" hide />
                    }
                    <XAxis type="number" hide />
                    { showChart && 
                        Object.keys(colors).map((key, index) => (
                            <Bar dataKey={key} fill={colors[key]} key={index} />   
                        ))
                    }
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
}

const DoubleSideBar = ({data, shield_data}) => {

    const bankNames = new Set();
    
    data.forEach(category => {
        Object.values(category).forEach(array => {
        array.forEach(item => {
            Object.keys(item).forEach(key => {
            if (key !== 'name') {
                bankNames.add(key);
            }
            });
        });
        });
    });
  
    const banks = Array.from(bankNames);

    const predefinedColors = ['#ff7300', '#387908', '#8884d8', '#413ea0', '#82ca9d'];

    const colors = {};
    banks.forEach((bank, index) => {
        colors[bank] = predefinedColors[index % predefinedColors.length];
    });
    
    data = data[0]
    const data1 = data["Wave 1"];
    const data2 = data["Wave 2"];
   
    function checkDataAvailability(data) {
        const isData1 = data.hasOwnProperty("Wave 1") && Boolean(data["Wave 1"]);
        const isData2 = data.hasOwnProperty("Wave 2") && Boolean(data["Wave 2"]);
        return { isData1, isData2 };
    }

    let {isData1, isData2 } = checkDataAvailability(data);
    let isData = isData1 && isData2;

    const legendData = Object.keys(colors).map(key => ({
        name: key, 
        color: colors[key]
    }));
    
    return (
        <div style={{ display: 'grid', gridTemplateColumns: '90% 10%', gridTemplateRows: '95% 5%', height: '100%', width: '100%' }}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                    
                    {!isData &&<CustomBarChart data={data1} title="-" reverse={true} showChart={false} isData={isData}  />}
                    {isData1 && <CustomBarChart data={data1} title="Wave 1" reverse={isData2} showChart={true} colors = {colors} />}
                    {(isData) &&<CustomBarChart data={data1} title="-" reverse={true} showChart={false} isData={isData} />}
                    {isData2 &&<CustomBarChart data={data2} title="Wave 2" reverse={false} showChart={true} colors = {colors}  />}
              
            </div>

            <div style={{
                position: 'relative',
                display: 'flex', 
                justifyContent: 'flex-end', // Aligns to the right
                alignItems: 'flex-end',     // Aligns to the bottom
            }}>
                <SmallShield 
                    scale='80%'
                    waveTxt={shield_data.waveTxt}
                    fill={shield_data.fill}
                />
            </div>
        
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'start', gridRow: '2 / 3', gridColumn: '1 / 2' }}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    {legendData.map((item, index) => (
                        <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ width: '15px', height: '15px', backgroundColor: item.color }} />
                            <div style={{ color: 'white', marginRight: '5px', paddingLeft: '5px' }}>
                                {item.name}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default DoubleSideBar;
