import { useState, useEffect } from 'react';
import axios from 'axios';
import constants from '../Usables/Constants';

const get_filters = (section_name, selectedMarket,set_filters_data,setSelectedYear,setLoading) => {
    const filters_url = constants.API_URL + `get_filter_values/${section_name}/${selectedMarket}/`;
    
    axios.get(filters_url)
        .then(response => {
            set_filters_data(response.data);
            // setSelectedYear(response.data.years[0])
            
            setLoading(false);
        })
        .catch(error => {
            console.error('Error fetching data: ', error);
            setLoading(false);
        });
}

const current_year = new Date().getFullYear();

const useDataFetcher = (country, section_obj, initialMarket = 'None', initialSegment = 'None') => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);

    const [filters_data, set_filters_data] = useState({"years": [], "markets": ["None"], "segments": ["None"]});

    const [selectedYear, setSelectedYear] = useState(current_year);
    const [selectedMarket, setSelectedMarket] = useState(initialMarket);
    const [selectedSegment, setSelectedSegment] = useState(initialSegment);

    const handleFilterChange = (selectedValue, setState, market) => {
        setState(selectedValue);
        if (market.is) {
            setSelectedSegment(0);
        }
        get_filters(section_obj.name, selectedMarket,set_filters_data,setSelectedYear,setLoading);
    };

    useEffect(() => {
        get_filters(section_obj.name, selectedMarket,set_filters_data,setSelectedYear,setLoading);
    }, [section_obj.name, selectedMarket]);

    useEffect(() => {
        // console.log(selectedYear);
        if (selectedYear) {
            setLoading(true);
            const fetchUrl = `${constants.API_URL}${section_obj.url_part}/${country}/${selectedYear}/${selectedMarket}/${selectedSegment}/`;
            // console.log(fetchUrl);
            
            axios.get(fetchUrl)
                .then(response => {
                    setData(response.data);
                    setLoading(false);
                })
                .catch(error => {
                    console.error('Error fetching data: ', error);
                    setLoading(false);
                });
        }
    }, [section_obj.url_part, country, selectedYear, selectedMarket, selectedSegment]);

    return { data, loading, filters_data, selectedYear, setSelectedYear, selectedMarket, setSelectedMarket, selectedSegment, setSelectedSegment, handleFilterChange };
};

export default useDataFetcher;
