import React from 'react';
import BlueBar from './BlueBar';
import Flag from './Flag';
import constants from './Constants';
import CapitalFirstLetter from './CapitalFirstLetter';

const ChartTitle = ({ Text, Icon, country, FillColor }) => {
    const bar_height = 60;

    return (
        <div style={{ display: 'flex' }}>
            <BlueBar imgSize={10} BarHeight={bar_height} Icon={Icon} Text={''} style={{width: '100%'}} FillColor = {FillColor} />
            <BlueBar imgSize={3} BarHeight={bar_height} Text={''} style={{width: '100%'}} FillColor='transparent' />
            <BlueBar imgSize={90} BarHeight={bar_height} Text={Text} FillColor = {FillColor}/>
            <div style={{ position: 'relative', right: -10, top: -10, height:'60px' }}>
                <Flag img={constants.country_flags[CapitalFirstLetter(country)].img} country={country.toUpperCase()} 
                    width={40}
                />
            </div>
        </div>
    );
};

export default ChartTitle;
