
const wmJsonData = {
8 : { title: "Always available and working self service banking",
    statement:[
    "User-friendly Cellphone banking",
    "Convenience of using cellphone banking",
    "Reliability of cellphone banking",
    "User-friendly Banking app",
    "Convenience of using a banking app",
    "Reliability of a banking app",
    "User-friendly ATMs",
    "Convenience of using an ATM",
    "Reliability of ATMs",
    "User-friendly Internet banking",
    "Convenience of using internet banking",
    "Reliability of internet banking",
    "User-friendly ANA machines",
    "Convenience of using ANA machines",
    "Reliability of ANA machines",
    "User friendly website",
    "User friendly / easy to use digital banking platform"]},

16: { title: "Reliable banking systems",
  statement: [
    "Debit orders should be debited on agreed date",
    "Systems should be reliable, online and working",
    "Stop orders should be debited on agreed date",
    "Honour payment arrangements made by customers",
    "Bank should debit the agreed amount"]},

14: { title: "Assistance in achieving financial growth and high returns",
  statement: [
    "Offer suitable/attractive investments",
    "Helping me to secure my financial future",
    "Earning interest on savings",
    "Saving money through the savings account",
    "Being able to invest/savings options",
    "Offer high interest rates on investment",
    "To be able to purchase shares",
    "Have higher forex interest rates",
    "Unlimited exchange rates on forex/no daily limits"]},

6: { title:  "Being acknowledged and treated as an individual and valued customer",
    statement: [
    "Should offer personalised service",
    "Understanding customer needs and requirements",
    "Do not ask for more documentation when they already have them on the system",
    "Ability to meet my needs as a client",
    "Having a single contact person dealing with my accounts and queries",
    "Stop regular staff change",
    "Cards to be delivered to customers",
    "Have a direct line to the banker and branch",
    "Good relationship between the banker and the client",
    "Acknowledge loyal clients",
    "For me to have a dignified funeral",
    "To be treated as a valued customer",
    "To have a personal banker (private / prestige / business banker)",
    "Bankers to keep to appointments",
    "Business banker to work remotely"]},

26: { title: "Diversity and Inclusion",
    statement:[
    "Cater for Islamic banking services",
    "Should be assisted in preferred language",
    "To be treated equally/fairly",
    "All documentation should be in prefered language",
    "To have racial balance of employees",
    "To have gender equality of employees"]},

4: { title: "Easily understood and accessible financial information",
  statement: [
    "Information should be easily accessible",
    "Should be informative/explain clearly",
    "Readily available information on products and services"
  ]},

2: { title: "Easily accessible banking solution/ facilities",
  statement:[
    "Ability to link accounts",
    "Ability to swipe anytime/anywhere",
    "Ability to use cardless services",
    "Accessibility of the branches",
    "Extend banking hours",
    "Opening on Sundays",
    "Account opening process should be easy",
    "ATM containing enough money",
    "Banking should be easy/user-friendly",
    "Being Internationally recognised",
    "Call centre should operate 24 hours",
    "Convenience of banking anytime anywhere",
    "Easily access to ATMs",
    "Easy access to banking app",
    "Easy access to banking facilities",
    "Easy access to cellphone banking",
    "Easy access to internet banking facilities",
    "Efficiency of banking app",
    "Efficiency of internet banking facilities",
    "Have more options and features on banking app",
    "Have more options and features on internet banking",
    "To have strict processes and procedures",
    "Have simple processes/procedures",
    "Making easy money transfers from my bank account to the other bank account.",
    "Should minimise paperwork required",
    "Offer a variety of products/services to choose from",
    "Should allow bulk withdrawals at the ATM",
    "Should have more ATMs",
    "Should have numerous branches available",
    "Should install ANA machines",
    "Should offer bursaries/scholarships",
    "Systems should be up to date",
    "Offer suitable/attractive insurance products",
    "Efficiency of cellphone banking",
    "Have foreign currency available/forex trading",
    "Offer stop card services",
    "Have more options and features on the ATM",
    "Reliable opening time",
    "Should have separate ATMs for SASSA customers",
    "Bank should always have enough money available",
    "Offer a mobile service to open accounts eg: business, school, clinics, rural areas",
    "ATMs should operate 24 hours",
    "Have enough stationery available e.g deposit slips / pens etc",
    "Offer a variety of payment options",
    "To be able to get a statement with a bank stamp at the ATM",
    "Efficiency of the ATMs",
    "To be able to download a statement that has a bank stamp",
    "To get copy of deposit slip after depositing at the ATM",
    "Should request a pin number instead of using the ' Tap & Go ' system",
    "A bank that moves my debit orders / debit order switching",
    "Offer suitable/attractive loan/credit products",
    "Self service should be in different languages",
    "ATM's to operate during loadshedding",
    "Download / activate / register digital banking platforms e.g cellphone banking",
    "Efficiency of the digital banking platform",
    "To have more options & features on the digital banking platform",
    "To check / access credit score",
    "To check uCount rewards",
    "To create a virtual card for online shopping",
    "Approve debi check",
    "To add beneficiaries"]},

12: { title: "Easy access to lending",
  statement: [
    "Access to loans/financial assistance",
    "To increase credit card limit",
    "Approval of loan facilities",
    "Quick approval of loans",
    "To increase loan / credit facility",
    "Extend loan repayment period",
    "Reduce loan repayment period",
    "Offer loans to pensioners",
    "Offer loans to people who are black listed",
    "Offer loans to people who are unemployed",
    "Offer loans to foreigners",
    "Access to loans during COVID 19",
    "Approval of full loan amount",
    "To check loan affordability",
    "To cancel loan application"]},

10: { title: "Easy access to my money",
  statement: [
    "Having access to my money all the time",
    "To get money when I am unemployed",
    "To be paid out when I make a claim / claim approval"
  ]},

11: { title: "Easy access to the bank/banker",
  statement: [
    "Bankers should be readily available/easy to get hold of",
    "Accessibility to financial advisor",
    "Consultants should be able to attend to my queries when I call",
    "The call centre must be easily accessible",
    "Ability to call the bank and access them any time",
    "Being able to find the contact number easily when you want to contact the bank",
    "Being able to contact the bank at any time",
    "Access to the bank manager",
    "Should have adequate staff during peak hours/adequate staff",
    "Manager should have authority to make decisions",
    "Should have bulk tellers",
    "Banker should have authority to make decisions",
    "Should allow customers to have 1 banker for both Business and Private banking",
    "Should allocate bankers in the same area as their clients"
  ]},

24: { title: "Efficient management of queues",
  statement: [
    "Should offer frontline assistance",
    "Queues should be well managed/reduce long queues",
    "Introduce the ticket system"
  ]},

22: { title: "Guaranteed safety of personal and confidential information",
  statement: [
    "Keep customer information confidential",
    "Ask some authentication questions so that no one gets access to my account",
    "Keeping record of customers information"
  ]},

15: { title: "Keeping in-touch with me",
  statement: [
    "Statement should be sent on time/reliably",
    "Should educate customers on how to use their products",
    "Information should be clear/ easy to understand",
    "Should introduce clients to their bankers",
    "Should visit clients who owns businesses",
    "Informing me about new products",
    "They should communicate regularly with me",
    "For the bank to know their customers",
    "Information should be sent to customers in their preferred channel eg: email,sms,post",
    "Communicate with clients telephonically",
    "Should have an online chat service",
    "Information should be sent to customers in their preferred language on email / sms / post"
  ]},

21: { title: "Knowledgeable and competent consultants",
  statement: [
    "Staff should be well trained/knowledgeable",
    "Service provides should be well trained / knowledgeable"
  ]},

27: { title: "Latest banking technology",
  statement: [
    "Should be innovative/up to date",
    "Have automated system where you do not have to fill in forms",
    "Have more options and features on cellphone banking",
    "Technology should be advanced",
    "Should offer  clients their own network sim cards"
  ]},

9: { title: "Paying low/affordable  banking fees",
    statement:[
    "Low interest rates on loans",
    "Low bank charges",
    "Affordable products",
    "Banking should be free",
    "Offer fixed monthly fees",
    "Charges/fees should be transparent",
    "Should not charge for returned / bounced debit orders",
    "Should not charge for card delivery",
    "Should not charge for cancellation of policies",
    "Should not have fixed monthly charges",
    "Should not be charged for renewal of card that was retained at the ATM",
    "Reduce penalty fees on investments that are taken before maturity",
    "Should not charge for unused overdraft facilities",
    "Should not charge interest on late payments",
    "Bankers should have authority to negotiate interest rates/charges",
    "Should not be charged for banking during 'Covid 19'",
    "Low interest rates on foreign exchange",
    "No interest charged on loans" ]},

13: { title: "Professional staff",
  statement: [
    "Banker should be dedicated/committed",
    "Should listen/pay attention to customers",
    "Staff should be helpful",
    "Staff should be friendly",
    "Services should be friendly to the elderly and pensioners",
    "Should be respectful",
    "Staff should be professional",
    "Staff should be committed and dedicated",
    "Staff should be patient",
    "Being treated well",
    "Staff should be presentable / easily identified"
  ]},

3: { title: "Prompt resolution of queries",
  statement: [
    "Provide feedback/feedback quickly",
    "Respond to queries/respond to queries quickly",
    "Resolve queries / resolve queries quickly "
]},

7: { title: "Real time and accurate communication",
  statement: [
    "Provide accurate information",
    "Statements should be detailed",
    "Money transferred/deposited should clear immediately/ reflect immediately",
    "Foreign exchange should clear immediately"
  ]},

5: { title: "Receiving prompt /efficient service",
  statement: [
    "Should answer calls quickly",
    "Getting help when I need it",
    "Service should be quick/efficient",
    "To have card delivered to the correct branch",
    "To move beneficiaries from one account to the other",
    "Should not be put on hold"
  ]},

19: { title: "Safe and secure banking environment",
  statement: [
    "Safe/secure  banking app",
    "Safe/secure internet banking",
    "Banking facility should be safe/secure",
    "Safe/secure cellphone banking",
    "No unauthorised debits",
    "Reverse unauthorised debit orders",
    "Should have privacy",
    "There must be a One time pin when using ATMs to prevent fraud",
    "ATMs must be placed in safe areas",
    "Ability to deal with fraud on internet banking",
    "The bank must trust its clients with regards to finances",
    "Have security guards at the ATMs",
    "No fraudulent activities on my account",
    "Should investigate fraudulent activities",
    "Reversal of payments / Reversal of stop order / debit order",
    "Safe/secure parking",
    "Introduce the biometric/fingerprint system",
    "Bank to refund customers when money is retained at the ATM",
    "Banking system should have a photo of their customers for verification purposes",
    "Bank to refund customers when fraud occurs",
    "Report lost card"
  ]},

1: { title: "Keeping my money safe",
  statement: [
    "To safely receive my salary through the bank",
    "Money to be safe/secure",
    "Ability to negotiate interest rates / charges",
    "Bank should not have fake notes",
    "Counting machine to be accurate"
  ],

  0: { title: "Staff's willingness to go the extra mile",
  statement: [
    "Going an extra mile to resolve the problems.",
    "Should be customer oriented",
    "Security guards assisting customers at the ATM's",
    "Staff always ready to assist",
    "Personal contact with my Prestige banker"
  ]},

  20: { title: "Support in making informed financial decisions",
  statement: [
    "Help me grow",
    "Offer advise and assistant",
    "Educating me on ways to save",
    "Offer products that are relevant to me",
    "Explain in detail when presenting a product"
  ]},

  29: { title: "Flexible access to my investment pay-out",
  statement: [
    "Accessibility of invested money / notice on stop order / savings"

  ]},

  18: { title: "Keeping me updated on my account/transactions",
  statement: [
    "Reliable messaging system/SMS notification",
    "Be informed about policy issues so that it does not lapse",
    "Follow up on claims made",
    "Send SMS's and emails to inform me of account activities",
    "Sending information on bank  charges",
    "Keep clients updated through sending banking statements",
    "Notify customers via SMS if the bank is experiencing any problems",
    "Regular communication regarding my investments",
    "Keeping me updated on all my transactions",
    "To have history statements available on request / to check transaction history",
    "SMS notification should be detailed"]},

  28: { title: "Transparency of the process",
  statement: [
    "Transparency when processing my applications, and being given feedback",
    "Should be transparent about products"

  ]},

  0: { title: "Efficient staff response to queries/problems/requests",
  statement: [
    "Quick service so that I can settle bills using the pay-out"
  ],


  17: { title: "Receiving benefits and rewards",
  statement: [
    "Offer good product benefits/rewards",
    "Additional benefits on credit cards",
    "Allow me to take a break from paying my home loan and car finance/payment holiday/relief",
    "Get benefits for banking with them, like access to the airport private lounge",
    "Unlimited swiping",
    "A bank that allows you to convert points into cash",
    "Unlimited withdrawals",
    "Offer extension on payment holiday",
    "Unlimited online money transfers",
    "To redeem uCount points"
  ]},

  25: { title: "Improve physical banking environment",
  statement: [
    "Offer beverages",
    "Provide seating",
    "Image of the bank",
    "Banking institutions should be clean and hygienic",
    "The Logo",
    "Have air-conditioning",
    "Have sanitary facilities",
    "Branch should be spacious"
  ]},

  23: { title:  "Trustworthy bank",
  statement: [
    "Bank abiding by the rules and regulations of the banking committee",
    "To get a big stable bank that can't be bankrupt easily",
    "Reliability of the banking institution for a client to trust the bank with their finances",
    "Transparency of the bank",
    "Accountability of the bank should money be deducted without the client's consent",
    "Trustworthiness/reputation of the bank",
    "Staff should be honest/trustworthy",
    "Should keep to their promises"
  ]},

  0: { title: "Quick claims pay-out",
  statement: [
    "Quick processing of claims / finalise my claims",
    "The turn around  time must be quick",
    "Reduce the amount of paper work required when claiming",
    "Progress on claims"
  ]},

  0: { title: "Full settlement of claim",
  statement: [
    "Settle my claim to full amount as agreed on the policy",
  ]},

  0: { title: "Hassle-free assistance when claiming",
  statement: [
    "In case i defaulted in premium payments, not to quickly lapse the policy",
    "Having only the most important investigations done when I have claim",
    "Be able to deal with one person when claiming",
    "Claim process must not be complicated"
  ]},

  0: { title: "Detailed explanation of features and benefits of the policy",
     statement: [
    "Clear explanation of premiums and pay-out amounts"

  ]},


  0: { title: "Other",
    statement:[
    "Stop calling customers offering products",
    "Information should be captured accurately",
    "To get my salary",
    "Offer free Wi-Fi/offer Wi-Fi",
    "Always have change available",
    "All branches should have the same information",
    "Update personal details regularly",
    "Have a toll free line",
    "Should  have an answering service",
    "Be lenient with foreigners when opening account",
    "Should not close inactive accounts",
    "Have separate queues for different transactions",
    "Calls to be put through to the relevant departments",
    "Immediate cover/no waiting period",
    "Being assisted over the phone without having to go to the branch",
    "Allow customers to choose their own service providers",
    "Make courtesy calls to customers",
    "Should conduct surveys regularly",
    "Have enough parking",
    "Should offer emergency services for customers with health problems",
    "Statements should be clear and easy to read",
    "Should stick to what they advertise",
    "To be able to deposit cash inside the branch",
    "Stop opening policies without customers consent",
    "Have clear signage",
    "Have an enquiries desk at the bank",
    "Have a back-up system",
    "Have a generator incase of power failure",
    "Have a suggestion box",
    "Give back to the community/social responsibility",
    "Offer good quality bank cards",
    "To be able to change bankers",
    "Having the insurance company dealing directly with me",
    "To have less notifications on advertisements from the bank",
    "Information on their website should be clear",
    "Should have a business section",
    "Prefer human interaction and not an answering service",
    "To be able to change personal details over the phone",
    "Should close inactive accounts",
    "To get a bank confirmation letter / download confirmation letter",
    "Offer money bags",
    "Should offer consistent service",
    "Should offer business workshops/training",
    "Display on the ATM should be clear / bigger",
    "Stop the ' Tap & Go ' system",
    "Have TV screen in the waiting areas",
    "Bank should verify if transactions are done by account holder",
    "Should not force customers to use digital banking",
    "Have ATM's for business bankers only",
    "Should call customers back if call is disconnected",
    "To get a deposit slip after depositing cash",
    "Should stop closing their branches",
    "To be able to withdraw money using any bank's ATM",
    "To have a commercial audit report that has all details required for the business",
    "Abiding by lockdown rules/regulations",
    "To provide sanitizers/gloves/masks",
    "Should have more self-service desks eg: internet/telephone banking",
    "Allow customers to choose their own pin",
    "Should print customer name on bank cards",
    "Convenient/reliable call centre",
    "To offer a drop safe",
    "Sound on ATMs should be on silent when transacting",
    "Should invite customers to functions / events",
    "Should open accounts for kids younger than 18 years of age",
    "Have shelters at their ATMs",
    "A bank that will instal ATMs inside shops/businesses",
    "Website should be updated",
    "Continue using documentation instead of electronic platforms",
    "Should not print account number on back card",
    "To use the ' Tap & Go ' card system",
    "Should provide sponsorships/donations when businesses do fundraising events",
    "Should have a home affairs department to renew passports/apply for ID",
    "To exchange coins for notes / notes for coins",
    "To have lights at the ATM as its too dark",
    "ATM should beep / have a sound when money comes out",
    "Tap & Go system should request a pin after tapping",
    "Should have a call back option / to leave a message",
    "Should increase payout when premiums increase",
    "Extend time limit on 1 time pin",
    "Risk management",
    "Banking support",
    "Wealth creation and preservation",
    "Should have different numbers for different departments",
    "Should not receive OTP number when logging in to business online",
    "To get tax certificate"

  ]},


  0: { title: "Affordable Premiums",
    statement: [
    "Charge reasonable premiums",
    "No excess fees should be payable when one claims",
    "Comprehensive cover must be affordable",
    "Low excess fees"
  ]},

  0: { title: "Covering all risk for me and my family",
    statement: [
    "My family should be covered when I pass on",
    "Get accidental cover",
    "All my property should be covered",
    "Secure my kids future",
    "Cover damages to car windscreen",
    "Cover for natural disaster",
    "Cover for vehicle theft",
    "Cover for road side assistance",
    "Cover for courtesy car",
    "Cover for loan protection"
  ]},

  0: { title: "Transparent communication about what the policy covers",
    statement: [
    "Cover must be well explained",
    "Highlight fine print",
    "Disclose hidden terms, conditions and clauses",
    "Sell insurance products to the right people"
  ]},

  0: { title: "Detailed explanation of claim requirements",
    statement: [
    "Clearly explain the documentation required to lodge a claim",
    "Insurance company must send customers policy documents"
  ]},

  0: { title: "Additional Policy Benefits", 
    statement: [
    "Offer pay back  when there are no claims",
    "Premiums must decrease with decreasing value of insured asset",
    "Have service providers working after hours"
  ]},

  0: { title: "Having my application or transaction succesfully processed",
    statement: [
    "To open account / Reactivate / Reinstate account",
    "To withdraw cash / money",
    "Closure of account / cancel account",
    "Card replacement / card renewal",
    "Update debit order details / change dates / details / increase / decrease debit order",
    "Reduce premiums / installment",
    "Account changes and upgrades",
    "To deposit cash / money",
    "To cancel a stop order / debit order",
    "Update stop order details / change dates / details / increase / decrease",
    "To block card",
    "Card collection",
    "To transfer money / funds",
    "To reset / change pin / password",
    "To make payments / pay bills / to pay electricity",
    "To make purchases / electricity / airtime / online purchases",
    "To cancel unauthorised debit order",
    "To increase / decrease withdrawal limit",
    "To download proof of payment",
    "To increase / decrease EAP limits",
    "To regoster for uCount rewards / to activate uCount",
    "To make payment arrangement e.g loan",
    "To play the lotto / betting",
    "To cancel uCount",
    "To draft a will",
    "To lodge a claim",
    "Loan settlement / pay off debt"

  ]},

  0: { title: "Receiving information on my account / request or query",
    statement: [
    "To get information about loans",
    "To get information about insurance",
    "To get information on my account balance / on any account / to check balance / last transaction",
    "To get information about charges / fees / interest rates",
    "To get information about investments / savings / interest on investments",
    "To get information on products and services",
    "To get a statement / download statement"

  ]},
  }}}

  export default wmJsonData;