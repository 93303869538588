import React, { useRef, useEffect, useState } from 'react';
import { XAxis, YAxis, ResponsiveContainer, ReferenceLine, BarChart, Bar, LabelList, Cell } from 'recharts';
import SmallShield from '../../Usables/SmallSheild';

const ColumnChart = ({ data, line = true, mainColor, colors, sample_size_data, shield_data }) => {

  let isGraphWave1 = false;
  let isGraphWave2 = false;

  data.forEach(item => {
    if ('Wave1' in item) {
      isGraphWave1 = true;
    }
    if ('Wave2' in item) {
      isGraphWave2 = true;
    }
  });

  const sampleSizes = sample_size_data[0];

  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end', height: '100%', width: '100%' }}>
      <div style={{ width: '100%' }}>
        <ResponsiveContainer width="100%">
          <BarChart data={data} margin={{ top: 20, bottom: 5 }}>
            <XAxis 
              dataKey="name" 
              tick={{ fill: '#ffffff', fontSize: 16, width: 50 }} 
              stroke='#ffffff' 
              height={100} 
              interval={0} 
             
            />
            {/* <YAxis 
              // domain={[0, 100]} 
              tick={{ fill: '#ffffff' }} 
              tickFormatter={!line ? null : (value) => `${value}%`} 
            /> */}
            {isGraphWave1 && (
              <Bar dataKey="Wave1">
                {isGraphWave2 ? (
                  data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={mainColor} />
                  ))
                ) : (
                  data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                  ))
                )}
                <LabelList 
                  dataKey="Wave1" 
                  position="top" 
                  style={{ fill: '#ffffff' }} 
                  formatter={!line ? null : (value) => `${value}%`} 
                />
              </Bar>
            )}
            {isGraphWave2 && (
              <Bar dataKey="Wave2">
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                ))}
                <LabelList 
                  dataKey="Wave2" 
                  position="top" 
                  style={{ fill: '#ffffff' }} 
                  formatter={!line ? null : (value) => `${value}%`} 
                />
              </Bar>
            )}
            {line &&  (
              <ReferenceLine
                x={data[1].name}
                stroke="#ffffff"
                strokeWidth={4}
                ifOverflow="extendDomain"
                style={{ display: 'flex' }}
              />
            )}
          </BarChart>
        </ResponsiveContainer>

        
        <div style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', width: '100%', gap: '10px' }}>
          {isGraphWave1 && (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ width: '15px', height: '15px', backgroundColor: '#ffffff' }} />
              <div style={{ color: 'white', marginRight: '5px', paddingLeft: '5px' }}>{`Wave 1 (n = ${sampleSizes.Wave1})`}</div>
            </div>
          )}
          {isGraphWave2 && (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ width: '15px', height: '15px', backgroundColor: 'blue' }} />
              <div style={{ color: 'white', marginRight: '5px', paddingLeft: '5px' }}>{`Wave 2 (n = ${sampleSizes.Wave2})`}</div>
            </div>
          )}
        </div>
      </div>

      <div style={{ 
        position: 'relative', 
        display: 'flex', 
        justifyContent: 'flex-end', 
        alignItems: 'flex-end', 
      }}>
        <SmallShield 
          scale='80%'
          waveTxt={shield_data.waveTxt}
          fill={shield_data.fill}
        />
      </div>
    </div>
  );
};

export default ColumnChart;
