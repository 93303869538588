import React from 'react';
import RingButton from './RingButton';
import BlueBar from './BlueBar';
import DottedConnector from './DottedConnector';

const SectionBar = ({ icon, imgSize, Text }) => {
    const circleHeight = imgSize*2; //

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px', width: '85%'  }}>
            <RingButton imageUrl={icon} imgSize={circleHeight} />
            <DottedConnector imgSize={imgSize*5} />
            <BlueBar imgSize={imgSize*0.8} BarHeight={100} Text={Text} />
        </div>
        
    );
};

export default SectionBar;
